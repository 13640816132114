import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
const ViewScan: FunctionComponent = () => {
    const navigate = useNavigate();
    return (
       <div>
           Scan
       </div>
    );
};

export default ViewScan;
