import React from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Routes, Route, Outlet, Link, useNavigate, useParams } from "react-router-dom";
import './App.css';
import ViewHome from './views/ViewHome';
import ViewScan from './views/ViewScan';
import awsExports from './aws-exports';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ViewCases from './views/ViewCases';

Amplify.configure(awsExports);

function App() {
  const navigate = useNavigate();
  return (
    <div>
      <AppMenu />
      <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<ViewHome />} />
            <Route path="home" element={<ViewHome />} />
            <Route path="cases" element={<ViewCases />} />
            <Route path="scan" element={<ViewScan />} />
            <Route path="scan/:eventId" element={<ViewScan />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
    </div>
  );

  function AppMenu() {
    return (
      <>
        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="#home">Evidence Locker</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="/home">Home</Nav.Link>
              <Nav.Link href="/cases">Cases</Nav.Link>
              <Nav.Link href="/incidents">Incidents</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }

  function Layout() {
    return (
      <div>
        {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
      </div>
    );
  }

  function NoMatch() {
    return (
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
    );
  }
}

export default withAuthenticator(App);