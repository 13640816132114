// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Attachments, Incidents, Cases, AttachmentsIncidents } = initSchema(schema);

export {
  Attachments,
  Incidents,
  Cases,
  AttachmentsIncidents
};