import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CasesCreateForm, { CasesCreateFormInputValues } from '../../ui-components/CasesCreateForm';
import { DataStore } from '@aws-amplify/datastore';
import { Cases } from '../../models';

const ViewHome: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
       <div>
           Home
       </div>
    );
};

export default ViewHome;
