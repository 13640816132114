import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CasesCreateForm, { CasesCreateFormInputValues } from '../../ui-components/CasesCreateForm';
import { DataStore } from '@aws-amplify/datastore';
import { Cases } from '../../models';

const ViewCases: FunctionComponent = () => {
    const navigate = useNavigate();

    async function onSubmit( formData: CasesCreateFormInputValues){
        let newCase = {
            "caseName": formData?.caseName,
            "caseDescription": formData?.caseDescription,
            "isOpen": formData?.isOpen,
            "Incidents": []
        };

        if(formData != null && formData != undefined)
            await DataStore.save(
                newCase
            );
            return newCase;
    }

    return (
       <div>
           <CasesCreateForm onSuccess={onSubmit} />
       </div>
    );
};

export default ViewCases;
